import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import {  PageContext } from '../utils/context'
import Heading from "../components/Heading"
import { useForm } from "react-hook-form"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { navigate } from 'gatsby'
import ButtonArrow from "src/images/button_arrow.svg"
import Seo from "src/utils/seo"

const ForgotPassword = ({ location }) => {

    const identity = useIdentityContext()
    const { register, handleSubmit, formState: { errors }} = useForm()
    const [formProcessing, setFormProcessing] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)
  
    const onSubmit = async (data) => {
      setFormProcessing(true)
      await identity
        .sendPasswordRecovery({ email: data.email })
        .catch(_ => _)
      setFormProcessing(false)
      setFormSubmitted(true)
    }
  
    // Current users don't need to confuse themselves
    useEffect(() => {
      identity.user && navigate('/')
    }, [identity.user])

    return (
        <PageContext.Provider
            value={{
                hasHero: false,
                currentPage: false,
                location
            }}
        >
            <Seo title={'Forgot Password'} description={'Enter your email to reset your password below.'} />
            <Layout location={location}>
                <div className="max-w-7xl mx-auto lg:py-24 py-12 px-6">
                    <div className="flex gap-x-8">
                        <div className="lg:w-6/12 w-full">
                            <Heading size="h2">Forgot your password?</Heading>
                            <p className="font-light max-w-2xl lg:text-[1.375rem] text-lg leading-7">Enter your email to reset your password below.</p>
        
                            <form className="pt-6 pb-8 mb-4" onSubmit={handleSubmit(onSubmit)}>
                                {formSubmitted ?
                                    <div>
                                        <p>Thanks! If that account exists, we'll send an email to reset the password.</p>
                                    </div>
                                    : <>
                                        <div className="mb-4">
                                        <label htmlFor="email" className="block font-light mb-2">
                                            Email
                                            <span className="text-orange"> *</span>
                                        </label>
                                        <input
                                            {...register('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                                            className={`appearance-none py-3 px-4 block w-full placeholder-gray-300 text-black focus:ring-transparent focus:border-orange border-gray-200 ${formProcessing && 'disabled'}`}
                                            type="text"
                                            placeholder="Email"
                                            name="email">
                                        </input>
                                        {errors.email && <p className="text-red-500 text-xs italic mt-1">Email is required</p>}
                                        </div>
                                        <div className="flex items-center justify-between mt-12">
                                            <button type="submit" className={`text-2xl flex duration-300 text-orange group hover:text-darkorange cursor-pointer ${formProcessing && 'opacity-50 cursor-not-allowed'}`}>        
                                                Reset            
                                                <ButtonArrow className="transition-transform duration-300 group-hover:rotate-45 ml-2 mt-2 fill-current" width="18" height="19" />
                                            </button>                                         
                                        </div>
                                    </>
                                }
                            </form>                                       
                        </div>
                    </div>
                </div>
            </Layout>        
        </PageContext.Provider>
    )
}

export default ForgotPassword